export const META_MASK = 'META_MASK';
export const WALLET_CONNECT = 'WALLET_CONNECT';
export const COINBASE = 'COINBASE'
export const IPFS_INITIALIZE = 'https://ipfs.infura.io:5001/api/v0';
export const IPFS_ROOT_STORAGE = 'https://ipfs.infura.io/ipfs/';

export const ETH_TO_WEI = Math.pow(10, 18);

// export const BACKGROUND_COLOR = '#0f041c';

export const BACKGROUND_COLOR = '#f5f8fa';

export const RE_FOR_URL = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm
