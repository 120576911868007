export const initialColors = [
  '#fb1c48',
  '#e41f66',
  '#d64b5e',
  '#a42e9a',
  '#9f29bc',
  '#902ccc',
  '#5951f6',
  '#4870f6',
  '#3096fa',
];


export const bountyTypes = [
  'Bug',
  'Feature Request',
  'Security',
  'Improvement',
  'Design',
  'Docs',
  'Code review',
  'Good first issue',
  'Other',
];