import { useState } from 'react';
import { Typography, TextField, Modal, Card, Button } from '@mui/material';
import { useProfile } from '../../contexts/ProfileContext';
import axios from 'axios';
import { initialColors } from '../../utils/arrays';
import { useNavigate } from 'react-router-dom';

export default function NewOrgModal({ open, handleClose }) {
  const [orgName, setOrgName] = useState('');
  const { userId } = useProfile();
  const navigate = useNavigate();
  const createOrg = () => {
    const initialProfileColor =
      initialColors[Math.floor(Math.random() * initialColors.length)];

    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/organizations/`, {
        organization: {
          name: orgName,
          organization_profile_picture_inital: initialProfileColor,
        },
        profile: userId,
      })
      .then((res) => navigate(`/organization/${res.data.organization_id}/`))
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      sx={{ position: 'absolute', top: '33%', left: '33%' }}
      open={open}
      onClose={handleClose}
    >
      <Card
        sx={{
          width: 600,
          height: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography marginBottom={3} variant='h4' fontWeight='600'>
          Create Org
        </Typography>
        <TextField
          onKeyDown={(e) => (e.key === 'Enter' ? createOrg() : null)}
          id='orgName'
          onChange={(e) => setOrgName(e.target.value)}
          value={orgName}
          sx={{
            width: 300,
            input: { textAlign: 'center', padding: 1, fontSize: 20 },
          }}
          placeholder='My Awesome Org 🙂'
        />
        <Button
          variant='contained'
          onClick={createOrg}
          sx={{
            marginTop: 2,
            borderRadius: 3,
            boxShadow: 'none',
            width: 300,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          Submit
        </Button>
      </Card>
    </Modal>
  );
}
