import { Button, Box, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../contexts/WindowSize';
import { BACKGROUND_COLOR } from '../utils/constants';
import axios from 'axios';
import { getTotalFundingAmount } from '../utils/helpers';
import { timeFromUpdateUtil } from '../utils/helpers';
import { stateEmojis, stateValue } from '../utils/objects';
import OrganizationProfilePicture from './organizations/OrganizationProfilePicture';

export default function BountyCell({ bounty }) {
  const [org, setOrg] = useState(null);
  const size = useWindowSize();
  const { id, title, funding_organization, state, updated_at, organization } =
    bounty;

  useEffect(() => {
    if (organization != null) {
      axios
        .get(
          `${process.env.REACT_APP_API_SERVER}/api/organization/${organization}/`
        )
        .then((res) => setOrg(res.data))
        .catch((err) => console.log(err));
    }
  }, [organization]);

  if (org == null) {
    return null;
  }

  return (
    <Card
      variant='outlined'
      sx={{
        borderRadius: 3,
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#BABBB9',
        backgroundColor: BACKGROUND_COLOR,
      }}
    >
      <Link
        to={`/organization/${org.organization_id}/bounty/${id}/`}
        style={{ textDecoration: 'none' }}
      >
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box display='flex'>
            <Box display='flex' alignItems='center'>
              <OrganizationProfilePicture
                orgId={organization}
                organization={org}
              />
              <Box marginLeft={2}>
                <Box display='flex' alignItems='center'>
                  <Typography
                    variant='body1'
                    sx={{
                      fontWeight: '600',
                      textOverflow: 'ellipses',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    color='main'
                  >
                    {title}
                  </Typography>
                </Box>
                <Typography
                  marginTop={1}
                  variant='body2'
                  fontWeight='500'
                  color='main'
                >
                  {org ? org.name : funding_organization}
                </Typography>
                {size.width > 500 ? (
                  <Box display='flex' marginTop={1}>
                    <Typography
                      variant='body2'
                      color='subColor'
                      marginRight={2}
                      sx={{
                        textOverflow: 'ellipses',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {stateEmojis[state] + ' ' + stateValue[state]}
                    </Typography>
                    <Typography color='subColor' marginRight={2}>
                      ·
                    </Typography>
                    <Typography
                      variant='body2'
                      color='subColor'
                      marginRight={2}
                      sx={{
                        textOverflow: 'ellipses',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {timeFromUpdateUtil(updated_at)}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box display='flex'>
            <FundingAmount bountyId={id} />
          </Box>
        </CardContent>
      </Link>
    </Card>
  );
}

function FundingAmount({ bountyId }) {
  const [fundingAmount, setFundingAmount] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/funding_transactions?bounty_id=${bountyId}`
      )
      .then((res) => {
        const fundingObjects = res.data;
        const amount = getTotalFundingAmount(fundingObjects);
        setFundingAmount(amount);
      })
      .catch((err) => console.log(err));
  }, [bountyId]);

  return (
    <Typography
      color='#D940FF'
      height={25}
      paddingLeft={2}
      paddingRight={2}
      backgroundColor={'rgb(144,44,204, 0.3)'}
      sx={{ borderRadius: 2 }}
    >
      ${fundingAmount}
    </Typography>
  );
}
