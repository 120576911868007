import {
  Box,
  Drawer,
  Divider,
  Typography,
  Checkbox,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { Logo } from '../utils/images';
import axios from 'axios';
import NewOrgModal from './modals/NewOrgModal';
import OrganizationProfilePicture from './organizations/OrganizationProfilePicture';
import { useProfile } from '../contexts/ProfileContext';

export default function SideNav({ dispatch }) {
  // const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <NewOrgModal open={open} handleClose={handleClose} />
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 110,
            // backgroundColor: '#0f041c',
            backgroundColor: '#31273C',
          },
          overflow: 'scroll',
        }}
        open
      >
        <Box marginLeft={2}>
          <Box marginLeft={1} marginTop={2}>
            <Logo />
          </Box>
          <Divider
            sx={{
              marginTop: 2,
              marginBottom: 2,
              marginRight: 2,
              backgroundColor: '#6D6D6D',
              alignSelf: 'center',
            }}
          />
          <Box>
            <Orgs />
            <Button sx={{ padding: 0, marginLeft: 1 }} onClick={handleOpen}>
              <AddBoxRoundedIcon
                sx={{
                  color: 'white',
                  width: 60,
                  height: 60,
                }}
              />
            </Button>
          </Box>
          {/* <Typography variant='body1' sx={{ fontWeight: '600' }} marginBottom={2}>
          Time Commitment
        </Typography>
        <NavCell type='Hours' text='Hours' dispatch={dispatch} />
        <NavCell type='Days' text='Days' dispatch={dispatch} />
        <NavCell type='Weeks' text='Weeks' dispatch={dispatch} />
        <NavCell type='Months' text='Months' dispatch={dispatch} />
        <Divider variant='middle' sx={{ marginTop: 3, marginLeft: 0 }} />
        <TextField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          variant='outlined'
          label='DAO Search'
          sx={{ marginTop: 3, marginLeft: 1, marginRight: 3, borderRadius: 0 }}
        />
        <Divider
          variant='middle'
          sx={{ marginBottom: 3, marginLeft: 0, marginTop: 3 }}
        />
        <Typography sx={{ fontWeight: '600' }} marginBottom={2}>
          Experience Level
        </Typography>
        <NavCell type='Beginner' text='Beginner' dispatch={dispatch} />
        <NavCell type='Intermediate' text='Intermediate' dispatch={dispatch} />
        <NavCell type='Advanced' text='Advanced' dispatch={dispatch} />
        <Divider
          variant='middle'
          sx={{ marginBottom: 3, marginLeft: 0, marginTop: 3 }}
        />
        <Typography sx={{ fontWeight: '600' }} marginBottom={2}>
          Status
        </Typography>
        <NavCell type='open' text='✅ Open' dispatch={dispatch} />
        <NavCell type='done' text='⌛️ Done' dispatch={dispatch} />
        <NavCell type='expired' text='🔒 Expired' dispatch={dispatch} />
        <NavCell type='cancelled' text='❌ Cancelled' dispatch={dispatch} /> */}
        </Box>
      </Drawer>
    </>
  );
}

function NavCell({ type, text, dispatch }) {
  return (
    <Box display='flex' alignItems='center' marginBottom={1}>
      <Checkbox
        onClick={() => dispatch({ type: type })}
        sx={{ padding: 0 }}
        value={type}
      />{' '}
      <Typography>{text}</Typography>
    </Box>
  );
}

function Orgs() {
  const { userId } = useProfile();
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    if (userId != null) {
      axios
        .get(
          `${process.env.REACT_APP_API_SERVER}/api/organization_members?user_id=${userId}`
        )
        .then((res) => setOrgs(res.data))
        .catch((err) => console.log(err));
    }
  }, [userId]);

  if (orgs == null) {
    return null;
  }

  return (
    <>
      {orgs.map((o) => (
        <OrganizationProfilePicture key={o.id} orgId={o.organization} />
      ))}
    </>
  );
}
