import { Container, Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProfile } from '../contexts/ProfileContext';
import axios from 'axios';
import { Button, CardMedia, Typography } from '@mui/material';
import BountyCell from './BountyCell';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

export default function Profile() {
  const params = useParams();
  const [profile, setProfile] = useState(null);
  const [createdBounties, setCreatedBounties] = useState([]);
  const [ownedBounties, setOwnedBounties] = useState([]);
  const { logout, authenticated, userId } = useProfile();

  const profileId = params.profileId;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/profile/${profileId}/`)
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  // TODO put this request when retrieving Profile Object
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/bounties?bounty_creator=${profileId}`
      )
      .then((res) => setCreatedBounties(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/bounties?bounty_owner=${profileId}`
      )
      .then((res) => setOwnedBounties(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  if (profile == null) {
    return null;
  }

  return (
    <Container>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <ProfilePic profile={profile} />
        <Box>
          <Box display='flex' alignItems='center'>
            <Typography
              marginTop={3}
              fontWeight='600'
              marginLeft={1}
              color='main'
            >
              {profile.name}
            </Typography>
            <InsertLinkIcon
              onClick={() =>
                window.open(`https://github.com/${profile.nickname}`, '_blank')
              }
              sx={{
                color: '#575757',
                marginLeft: 4,
                cursor: 'pointer',
                marginTop: 3,
              }}
              fontSize='large'
            />
          </Box>
          {authenticated && userId == profile.id ? (
            <Button
              sx={{ marginTop: 3 }}
              variant='outlined'
              onClick={() => logout()}
            >
              Logout
            </Button>
          ) : null}
        </Box>
      </Box>
      <Typography marginTop={5} variant='h5' color='main' fontWeight='600'>
        Bounties Created
      </Typography>
      {createdBounties.length > 0 ? (
        createdBounties.map((created) => (
          <BountyCell key={created.id} bounty={created} />
        ))
      ) : (
        <Typography marginTop={3} color='main'>
          No Bounties Created Yet!
        </Typography>
      )}
      <Typography marginTop={10} variant='h5' color='main' fontWeight='600'>
        Bounties Currently Working On
      </Typography>
      <Box marginBottom={15}>
        {ownedBounties.length > 0 ? (
          ownedBounties.map((owned) => (
            <BountyCell key={owned.id} bounty={owned} />
          ))
        ) : (
          <Typography marginTop={3} color='main'>
            Not Currently working on Any
          </Typography>
        )}
      </Box>
    </Container>
  );
}

function ProfilePic({ profile }) {
  return (
    <Box display='flex' textAlign='center'>
      <CardMedia
        component='img'
        sx={{
          width: 100,
          height: 100,
          borderRadius: 50,
          marginRight: 5,
          cursor: 'pointer',
        }}
        image={profile.profile_picture}
      />
    </Box>
  );
}
