import { Box, Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  CardMedia,
  Divider,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { checkIfInOrg } from '../utils/helpers';
import { useProfile } from '../contexts/ProfileContext';
import ImageEnlargeModal from './modals/ImageEnlargeModal';
import { ActivityCell } from './ActivityCell';
import MDEditor from '@uiw/react-md-editor';
import { BACKGROUND_COLOR } from '../utils/constants';
import { stateEmojis, stateValue } from '../utils/objects';
import OrganizationProfilePicture from './organizations/OrganizationProfilePicture';
import ProfilePicture from './ProfilePicture';
import FundBountyModal from './modals/FundBountyModal';
import FundingAmount from './FundingAmount';

const state = ['open', 'done', 'cancelled', 'expired'];

export default function BountyDetails() {
  const [bounty, setBounty] = useState(null);
  const [orgMembers, setOrgMembers] = useState([]);

  const params = useParams();
  const bountyId = params.bountyId;
  const orgId = params.orgId;

  const { userId } = useProfile();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/bounty/${bountyId}/`)
      .then((res) => setBounty(res.data))
      .catch((err) => console.log('😢 ' + err));
  }, [bountyId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/organization/${orgId}/`)
      .then((res) => {
        axios
          .get(
            `${process.env.REACT_APP_API_SERVER}/api/organization_members?organization_id=${res.data.id}`
          )
          .then((res) => setOrgMembers(res.data));
      })
      .catch((err) => console.log(err));
  }, [orgId]);

  if (bounty == null) {
    return null;
  }

  const isMember = checkIfInOrg(orgMembers, userId);

  const handleStateChange = async (e) => {
    setBounty({ ...bounty, state: e.target.value });
    // Don't need to add activity for this change
    await axios
      .patch(`${process.env.REACT_APP_API_SERVER}/api/bounty/${bountyId}/`, {
        bounty: { state: e.target.value },
      })
      .then((res) => console.log('✅ status changed', res))
      .catch((err) => console.log(err));
  };
  return (
    <Container>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex'>
          <OrganizationProfilePicture orgId={bounty.organization} />
          <Box marginLeft={2}>
            <Box display='flex' textAlign='center'>
              <Typography variant='h6' marginBottom={2} color='main'>
                {bounty.title}
              </Typography>
              {userId === bounty.creator_profile ? (
                <Link to={`edit/`}>
                  <EditIcon
                    sx={{
                      color: 'rgb(251,28,72, 0.6)',
                      marginLeft: 4,
                      cursor: 'pointer',
                      marginTop: 0.5,
                    }}
                  />
                </Link>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box></Box>
      </Box>
      <Box display='flex' marginTop={4} justifyContent='space-between'>
        <Box display='flex'>
          <Creator profileId={bounty.creator_profile} />
          {/* <CreatorContactInfo contactInfo={bounty.ways_to_contact} /> */}
          <FundingAmount bountyId={bountyId} />
        </Box>
        <Box display='flex'>
          {/* {bounty.state === 'open' ? (
            <WorkingOnBounty bountyOwnersWallets={bounty.bounty_owner_wallet} />
          ) : null} */}
          <BountyStatus
            bounty={bounty}
            handleStateChange={handleStateChange}
            isMember={isMember}
          />
          {/* <WhenCreated timeLapse={bounty.updated_at} /> */}
        </Box>
      </Box>
      {/* <Funders bountyId={bountyId} /> */}
      <Box display='flex' alignItems='center'>
        <ButtonActionsLogic bounty={bounty} isMember={isMember} />
        {bounty.ways_to_contact === 'jamestouri50@gmail.com' ? (
          <Typography marginTop={4} marginLeft={2}>
            Owners of {orgId} contact jamestouri50@gmail.com
          </Typography>
        ) : null}
      </Box>

      <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
      <Typography variant='h6' fontWeight='500' color='main'>
        Description
      </Typography>
      <MDEditor.Markdown
        style={{
          padding: 15,
          backgroundColor: '#E9E9E9',
          color: 'black',
        }}
        source={bounty.description}
        linkTarget='_blank'
      />
      {bounty.attached_job_url ? (
        <Box marginLeft={2}>
          <Button
            onClick={() => {
              window.open(bounty.attached_job_url, '_blank');
            }}
            sx={{ padding: 0, fontSize: 16, color: '#1db3f9' }}
            target='_blank'
          >
            Link to Github Issue
          </Button>
        </Box>
      ) : null}
      <FilesAndImages imageAttachments={bounty.image_attachments} />
      <Activities bountyId={bounty.id} />
    </Container>
  );
}

function Creator({ profileId }) {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/profile/${profileId}/`)
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  if (profile == null) {
    return null;
  }
  return (
    <Box marginRight={3}>
      <Typography color='#757575' fontWeight='600'>
        Creator
      </Typography>
      <Link to={`/profile/${profileId}`} style={{ textDecoration: 'none' }}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          marginTop={1}
        >
          <CardMedia
            component='img'
            sx={{
              width: 40,
              height: 40,
              borderRadius: 50,
              marginRight: 5,
              cursor: 'pointer',
            }}
            image={profile.profile_picture}
          />
          <Button sx={{ padding: 0, fontSize: 16, color: '#649ddf' }}>
            {profile.name}
          </Button>
        </Box>
      </Link>
    </Box>
  );
}

function CreatorContactInfo({ contactInfo }) {
  return (
    <Box marginRight={2}>
      <Typography color='#757575' fontWeight='600'>
        Point of Contact
      </Typography>
      <Typography fontWeight='600' color='main'>
        ✉️ {contactInfo}
      </Typography>
    </Box>
  );
}

function BountyStatus({ bounty, handleStateChange, isMember }) {
  // Going to allow bounty owner, and org owners to change the status of the bounty
  const { userId } = useProfile();
  return (
    <Box marginRight={2}>
      <Typography color='#757575' fontWeight='600'>
        Status
      </Typography>
      {userId === bounty.creator_profile || isMember ? (
        <Select
          value={bounty.state}
          onChange={handleStateChange}
          sx={{ backgroundColor: 'main', borderRadius: 3 }}
        >
          {state.map((option) => (
            <MenuItem value={option} key={option}>
              {stateEmojis[option] + ' ' + stateValue[option]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography color='main' fontWeight='600'>
          {stateEmojis[bounty.state] + ' ' + stateValue[bounty.state]}
        </Typography>
      )}
    </Box>
  );
}

function ButtonActionsLogic({ bounty, isMember }) {
  const { userId, user } = useProfile();
  const [showLoginErrorText, setShowLoginErrorText] = useState(false);
  const params = useParams();
  const bountyId = params.bountyId;
  const [loading, setLoading] = useState(false);
  const orgId = params.orgId;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // if (isMember) {
  //   return null;
  // }
  const handleFundWork = async () => {
    if (userId == null) {
      setShowLoginErrorText(true);
    } else {
      handleOpen();
    }
  };

  return (
    <>
      <FundBountyModal
        bountyId={bountyId}
        orgId={bounty.organization}
        open={open}
        handleClose={handleClose}
        orgNickName={orgId}
      />
      <Button
        onClick={!loading ? handleFundWork : null}
        variant='contained'
        sx={{
          marginTop: 4,
          borderRadius: 3,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'rgb(29,179,249)',
          },
          backgroundColor: 'rgb(29,179,249, 0.7)',
          color: 'white',
        }}
      >
        {loading ? (
          <CircularProgress
            size={18}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : null}
        Fund Request
      </Button>
      {showLoginErrorText ? (
        <Typography marginTop={2} fontWeight={600} color='main'>
          Login First!
        </Typography>
      ) : null}
    </>
  );
}

function FilesAndImages({ imageAttachments }) {
  const [imageToEnlarge, setImageToEnlarge] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (url) => {
    setImageToEnlarge(url);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImageToEnlarge(null);
  };

  if (imageAttachments == null || imageAttachments.length === 0) {
    return null;
  }

  return (
    <>
      <ImageEnlargeModal
        imageURL={imageToEnlarge}
        open={open}
        handleClose={handleClose}
      />
      <Typography
        variant='h6'
        fontWeight='500'
        sx={{ marginTop: 8, marginBottom: 3 }}
        color='main'
      >
        File Attachments
      </Typography>
      <Box display='flex' flexWrap='wrap'>
        {imageAttachments.map((imageURL) => (
          <React.Fragment key={imageURL}>
            <CardMedia
              component='img'
              onClick={() => handleOpen(imageURL)}
              sx={{
                width: 100,
                height: 100,
                marginRight: 5,
                cursor: 'pointer',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'rgb(22,22,22, 0.2)',
              }}
              image={imageURL}
            />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
}

function Activities({ bountyId }) {
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/activities?bounty_id=${bountyId}`
      )
      .then((res) => setActivities(res.data))
      .catch((err) => console.log(err));
  }, [bountyId]);
  if (activities == null) {
    return null;
  }
  return (
    <>
      <Divider
        sx={{ marginTop: 3, marginBottom: 3, backgroundColor: 'main' }}
      />
      <Typography
        variant='h6'
        fontWeight='500'
        sx={{ marginBottom: 5 }}
        color='main'
      >
        Activity History
      </Typography>
      <Box>
        {activities.map((a) => (
          <ActivityCell key={a.id} activity={a} />
        ))}
      </Box>
    </>
  );
}
