import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

export default function FundBountyModal({
  bountyId,
  orgId,
  open,
  handleClose,
  orgNickName,
}) {
  const [accountId, setAccountId] = useState(null);
  const [amount, setAmount] = useState(100);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/stripe_account/${orgId}/`)
      .then((res) => setAccountId(res.data.stripe_account_id))
      .catch((err) => console.log(err));
  }, [orgId]);

  const handleChargeCreation = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_SERVER}/api/start_destination_charge/`,
        {
          amount: amount,
          account_id: accountId,
          bounty_id: bountyId,
          org: orgNickName,
        }
      )
      .then((res) => {
        window.open(res.data.url);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  return (
    <Modal
      sx={{ position: 'absolute', top: '33%', left: '33%' }}
      open={open}
      onClose={handleClose}
    >
      <Card
        sx={{
          width: 600,
          height: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5'>Back this Bounty</Typography>
        <Box display='flex' marginTop={4}>
          <Typography fontSize={18} fontWeight={600} marginTop={0.5}>
            $
          </Typography>
          <TextField
            type='number'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant='standard'
            sx={{
              input: { textAlign: 'center', fontSize: 18, fontWeight: 600 },
            }}
          />
        </Box>
        <Button
          variant='contained'
          onClick={handleChargeCreation}
          sx={{
            color: 'white',
            boxShadow: 'none',
            marginTop: 4,
            backgroundColor: '#3096fa',
            fontWeight: '600',
            borderRadius: 3
          }}
        >
          {loading ? (
            <CircularProgress
              size={18}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : null}
          Fund Request
        </Button>
      </Card>
    </Modal>
  );
}
