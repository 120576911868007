import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { getTotalFundingAmount } from '../utils/helpers';

export default function FundingAmount({ bountyId }) {
  const [fundingAmount, setFundingAmount] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/funding_transactions?bounty_id=${bountyId}`
      )
      .then((res) => {
        const fundingObjects = res.data;
        const amount = getTotalFundingAmount(fundingObjects);
        setFundingAmount(amount);
      })
      .catch((err) => console.log(err));
  }, [bountyId]);

  if (fundingAmount === 0) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography color='#757575' fontWeight='600'>
        Total Funding Amount
      </Typography>
      <Typography fontSize={18} fontWeight={600} color='main'>
        ${fundingAmount}
      </Typography>
    </Box>
  );
}
