import { useEffect, useState } from 'react';
import axios from 'axios';
import { CardMedia, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProfilePicture({ profileId, dimension = 80 }) {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/profile/${profileId}/`)
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  if (profile == null) {
    return (
      <Box
        sx={{
          height: dimension,
          width: dimension,
          borderRadius: dimension / 2,
          backgroundColor: '#1DB3F9',
        }}
      />
    );
  }

  return (
    <Link to={`/profile/${profileId}/`} style={{ textDecoration: 'none' }}>
      <CardMedia
        component='img'
        sx={{
          width: dimension,
          height: dimension,
          borderRadius: dimension / 2,
          marginRight: 3,
          cursor: 'pointer',
        }}
        image={profile.profile_picture}
      />
    </Link>
  );
}
