import {
  Box,
  CircularProgress,
  Container,
  Button,
  CardMedia,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useProfile } from '../../contexts/ProfileContext';
import {
  checkIfInOrg,
  getTotalFundingAmount,
  storeFilesInIPFS,
} from '../../utils/helpers';
import BountyCell from '../BountyCell';
import { Link } from 'react-router-dom';
import ProfilePicture from '../ProfilePicture';
import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const buttonStyle = {
  backgroundColor: 'rgb(228,31,102)',
  borderRadius: 3,
  boxShadow: 'none',
  height: 40,
  color: 'white',
  fontSize: 14,
  alignSelf: 'center',
  marginLeft: 2,
  marginTop: 2,
  '&:hover': {
    backgroundColor: 'rgb(228,31,102, 0.7)',
    boxShadow: 'none',
  },
};

export default function Organization() {
  const params = useParams();
  const [org, setOrg] = useState(null);
  const [bounties, setBounties] = useState([]);
  const [orgMembers, setOrgMembers] = useState([]);
  const { userId } = useProfile();

  const orgId = params.orgId;

  const isMember = checkIfInOrg(orgMembers, userId);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/organization/${orgId}/`)
      .then((res) => {
        setOrg(res.data);
        axios
          .get(
            `${process.env.REACT_APP_API_SERVER}/api/bounties?organization=${res.data.id}`
          )
          .then((res) => setBounties(res.data));
        axios
          .get(
            `${process.env.REACT_APP_API_SERVER}/api/organization_members?organization_id=${res.data.id}`
          )
          .then((res) => setOrgMembers(res.data));
      })
      .catch((err) => console.log(err));
  }, [orgId]);

  if (org == null) {
    return null;
  }
  return (
    <Container>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' alignItems='center'>
          <OrgPicture org={org} orgMembers={orgMembers} />
          {isMember ? (
            <Link to={`edit/`}>
              <EditIcon
                sx={{
                  color: 'rgb(251,28,72, 0.6)',
                  marginLeft: 4,
                  cursor: 'pointer',
                  marginTop: 0.5,
                }}
              />
            </Link>
          ) : null}
          {org.organization_url ? (
            <InsertLinkIcon
              onClick={() => window.open(org.organization_url, '_blank')}
              sx={{
                color: '#575757',
                marginLeft: 4,
                cursor: 'pointer',
                marginTop: 0.5,
              }}
              fontSize='large'
            />
          ) : null}
        </Box>
        <Link to={`bounty/new/`} style={{ textDecoration: 'none' }}>
          <Button
            sx={{
              boxShadow: 'none',
              backgroundColor: 'rgb(29,179,249, 0.7)',
              color: 'white',
            }}
            variant='contained'
          >
            Create Request
          </Button>
        </Link>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box></Box>
        <FundingAmount orgId={org.id} />
        <MemberTab org={org} orgMembers={orgMembers} />
      </Box>
      {isMember ? <SettingUpAccount org={org} /> : null}
      {bounties.map((b) => (
        <BountyCell key={b.id} bounty={b} />
      ))}
    </Container>
  );
}

function FundingAmount({ orgId }) {
  const [fundingAmount, setFundingAmount] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/funding_transactions?organization_id=${orgId}`
      )
      .then((res) => {
        const fundingObjects = res.data;
        const amount = getTotalFundingAmount(fundingObjects);
        setFundingAmount(amount);
      })
      .catch((err) => console.log(err));
  }, [orgId]);

  if (fundingAmount === 0) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography color='#757575' fontWeight='600'>
        Total Funding Amount
      </Typography>
      <Typography marginTop={2} fontSize={24} fontWeight={600} color='main'>
        ${fundingAmount}
      </Typography>
    </Box>
  );
}

function MemberTab({ org, orgMembers }) {
  return (
    <Box>
      <Typography color='#757575' fontWeight='600'>
        {org.name} Members
      </Typography>
      <Box display='flex' marginTop={2}>
        {orgMembers.map((o, i) => (
          <Box key={i} sx={{ zIndex: i, marginLeft: i > 0 ? -4 : 0 }}>
            <ProfilePicture profileId={o.profile} dimension={30} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function OrgPicture({ org, orgMembers }) {
  const { userId } = useProfile();
  const [orgPicture, setOrgPicture] = useState(org.profile_picture);

  const handleImageChange = async (image) => {
    const stored = await storeFilesInIPFS(image);
    // image link in profile object
    axios
      .patch(
        `${process.env.REACT_APP_API_SERVER}/api/organization/${org.id}/`,
        {
          organization: { profile_picture: stored[0] },
        }
      )
      .then((res) => setOrgPicture(res.data.profile_picture))
      .catch((err) => console.log(err));
  };

  return (
    <Box display='flex' alignItems='center'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        {orgPicture == null ? (
          <Box
            sx={{
              marginLeft: 0.25,
              height: 100,
              width: 100,
              borderRadius: 100 / 2,
              backgroundColor: org.organization_profile_picture_initial,
            }}
          />
        ) : (
          <CardMedia
            component='img'
            sx={{
              width: 100,
              height: 100,
              borderRadius: 100 / 2,
              marginRight: 3,
              cursor: 'pointer',
            }}
            image={org.profile_picture}
          />
        )}
        {checkIfInOrg(orgMembers, userId) ? (
          <Button component='label' variant='contained' sx={buttonStyle}>
            Change Image
            <input
              alt='image in here'
              type='file'
              name='image'
              accept='.jpg, .jpeg, .png'
              hidden
              onChange={(e) => handleImageChange(e.target.files)}
            />
          </Button>
        ) : null}
      </Box>
      <Typography fontWeight='600' marginLeft={3} color='main' variant='h4'>
        {org.name}
      </Typography>
    </Box>
  );
}

function SettingUpAccount({ org }) {
  const [accountId, setAccountId] = useState(null);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useProfile();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/stripe_account/${org.id}/`)
      .then((res) => {
        const resAccountId = res.data ? res.data.stripe_account_id : null;
        setAccountId(resAccountId);
      })
      .catch((err) => console.log(err));
  }, [org.id]);

  useEffect(() => {
    if (accountId) {
      axios
        .get(
          `${process.env.REACT_APP_API_SERVER}/api/check_if_details_submitted/${accountId}/`
        )
        .then((res) => {
          const details = res.data.details_submitted
            ? res.data.details_submitted
            : false;

          setDetailsSubmitted(details);
        });
    }
  }, [org.id, accountId]);

  if (detailsSubmitted) {
    return null;
  }

  const handleAccountCreation = () => {
    setLoading(true);
    const stripeAccounts = {
      organization: org.id,
      account_creator: user.id,
      email: user.email,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_SERVER}/api/stripe_accounts/`,
        stripeAccounts
      )
      .then((res) => handleAccountLinkCreation(res.data.stripe_account_id))
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleAccountLinkCreation = (accountId) => {
    setLoading(true);
    const accountLink = {
      org: org.id,
      account_id: accountId,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_SERVER}/api/create_account_link/`,
        accountLink
      )
      .then((res) => {
        window.open(res.data.url);
      })
      .catch((err) => console.error(err));
    setLoading(false);
  };

  if (!accountId) {
    return (
      <>
        <Button
          component='label'
          variant='contained'
          sx={{ ...buttonStyle, backgroundColor: '#1db3f9', fontSize: 18 }}
          onClick={!loading ? handleAccountCreation : null}
        >
          {loading ? (
            <CircularProgress
              size={18}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : null}
          Add your account
        </Button>
        <Typography marginLeft={2} marginTop={2} color='subColor'>
          Connect to Stripe and start getting paid!
        </Typography>
      </>
    );
  }

  return (
    <>
      <Button
        component='label'
        variant='contained'
        sx={{ ...buttonStyle, backgroundColor: '#1db3f9', fontSize: 18 }}
        onClick={!loading ? () => handleAccountLinkCreation(accountId) : null}
      >
        {loading ? (
          <CircularProgress
            size={18}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        ) : null}
        Finish account creation
      </Button>
      <Typography marginLeft={2} marginTop={2} color='subColor'>
        Account set up was unfinished
      </Typography>
    </>
  );
}
