import { createContext, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileContext = createContext({
  userId: null,
  user: null,
  authenticated: false,
  login: () => {},
  logout: () => {},
});

export function ProfileProvider({ children }) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const auth = useAuth0();

  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  const getProfileFromDB = () => {
    if (isAuthenticated) {
      axios
        .get(`${process.env.REACT_APP_API_SERVER}/api/profile/${auth.user.sub}/`)
        .then((res) => {
          if (!res.data) {
            createProfileFromAuth0Id();
          } else {
            setUserId(res.data.id);
            setUser(res.data);
          }
          setAuthenticated(true);
        })
        .catch((err) => console.log(err));
    } else {
      return;
    }
  };

  const createProfileFromAuth0Id = () => {
    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/profiles/`, {
        user_id: user.sub,
        profile_picture: user.picture,
        email: user.email,
        nickname: user.nickname,
        name: user.name,
      })
      .then((res) => setUserId(res.data.id))
      .catch((err) => console.log('❗️ ' + err));
  };

  const login = () => {
    loginWithRedirect();
  }

  const logout = () => {
    logout({ returnTo: window.location.origin });
  }

  useEffect(() => {
    getProfileFromDB();
  }, [auth.user]);

  const ctx = {
    userId,
    user,
    authenticated,
    login,
    logout,
  };

  return (
    <ProfileContext.Provider value={ctx}>{children}</ProfileContext.Provider>
  );
}

export const useProfile = () => {
  return useContext(ProfileContext);
};
