export const stateEmojis = {
  cancelled: '❌',
  open: '✅',
  done: '⌛️',
  expired: '🔒',
};

export const stateValue = {
  open: 'Open Bounty',
  done: 'Done',
  cancelled: 'Cancelled',
  expired: 'Expired',
};

export const bountyTypeColor = {
  Bug: '#fb1c48',
  'Feature Request': '#1db3f9',
  Security: '#d64b5e',
  Improvement: '#5951f6',
  Other: '#4870f6',
  Design: '#d64b5e',
  Docs: '#d64b5e',
  'Code Review': '#d64b5e',
  'Good first issue': '#e41f66',
  Project: '#4870f6',
};
