import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { CardMedia } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function OrganizationProfilePicture({
  orgId,
  dimension = 80,
  organization = null,
}) {
  const [org, setOrg] = useState(organization);

  useEffect(() => {
    if (orgId != null && org == null) {
      axios
        .get(`${process.env.REACT_APP_API_SERVER}/api/organization/${orgId}/`)
        .then((res) => setOrg(res.data))
        .catch((err) => console.log(err));
    }
  }, [orgId, org]);

  if (org == null) {
    return (
      <Box
        marginBottom={2}
        sx={{
          height: dimension,
          width: dimension,
          borderRadius: dimension / 2,
          marginRight: 2,
          backgroundColor: '#9f29bc',
        }}
      />
    );
  }

  return (
    <Link
      to={`/organization/${org.organization_id}/`}
      style={{ textDecoration: 'none' }}
    >
      {org.profile_picture == null ? (
        <Box
          marginBottom={2}
          sx={{
            height: dimension,
            width: dimension,
            marginRight: 2,
            borderRadius: dimension / 2,
            backgroundColor: org.organization_profile_picture_initial,
          }}
        />
      ) : (
        <CardMedia
          component='img'
          sx={{
            width: dimension,
            height: dimension,
            borderRadius: dimension / 2,
            marginRight: 2,
            cursor: 'pointer',
            marginBottom: 2,
          }}
          image={org.profile_picture}
        />
      )}
    </Link>
  );
}
