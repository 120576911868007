import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ProfileNameFromId({ profileId }) {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/profile/${profileId}/`)
      .then((res) => setProfile(res.data))
      .catch((err) => console.log(err));
  }, [profileId]);

  if (profile == null) {
    return null;
  }

  return (
    <Link to={`/profile/${profile.id}`} style={{ textDecoration: 'none' }}>
      <Button sx={{ fontSize: 16, padding: 0, color: '#649ddf' }}>
        {profile.name}
      </Button>
    </Link>
  );
}
