import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { RE_FOR_URL } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useProfile } from '../../contexts/ProfileContext';
import {
  Box,
  CircularProgress,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  // FormControlLabel,
  // Checkbox,
  // MenuItem,
  // Select,
} from '@mui/material';
import { useFormik } from 'formik';
import { checkIfInOrg } from '../../utils/helpers';

const textFieldStyle = {
  backgroundColor: 'main',
  padding: 0.1,
  marginTop: 2,
};

export default function EditOrganization() {
  const params = useParams();
  const { authenticated, userId } = useProfile();
  const navigate = useNavigate();

  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgMembers, setOrgMembers] = useState([]);
  const [error, setError] = useState(false);

  const orgId = params.orgId;

  const isMember = checkIfInOrg(orgMembers, userId);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/organization/${orgId}/`)
      .then((res) => {
        setOrg(res.data);
        axios
          .get(
            `${process.env.REACT_APP_API_SERVER}/api/organization_members?organization_id=${res.data.id}`
          )
          .then((res) => setOrgMembers(res.data));
      })
      .catch((err) => console.log(err));
  }, [orgId]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Title is required')
      .min(2, 'At least 8 characters'),
    organization_url: Yup.string()
      .matches(RE_FOR_URL, 'URL not valid (must have http/s)')
      .nullable(),
    external_url_of_requests: Yup.string()
      .matches(RE_FOR_URL, 'URL not valid (must have http/s)')
      .nullable(),
  });

  const initialValues = {
    name: org ? org.name : '',
    organization_url: org ? org.organization_url : '',
    external_url_of_requests: org ? org.external_url_of_requests : '',
  };

  const formik = useFormik({
    initialValues: org ? org : initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => await saveOrgEdits(values),
    enableReinitialize: true,
  });

  const catchSets = () => {
    setLoading(false);
    setError(true);
  };

  const saveOrgEdits = async (formData) => {
    setLoading(true);
    const data = { ...formData };

    await axios
      .patch(`${process.env.REACT_APP_API_SERVER}/api/organization/${orgId}/`, {
        organization: data,
      })
      .then((res) => {
        console.log('✅ bounty edited', res);
        navigate(`/organization/${orgId}/`);
      })
      .catch(() => catchSets());
    setLoading(false);
  };

  if (!isMember || !authenticated) {
    return null;
  }

  return (
    <Container>
      <Box display='flex' flexDirection='column'>
        <Typography color='primary' variant='h4'>
          Edit Org
        </Typography>
        <FormControl style={{ width: '50%' }}>
          <Title text={'Name'} />
          <TextField
            fullWidth
            id='name'
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant='outlined'
            required
            inputProps={{ maxLength: 80 }}
            sx={textFieldStyle}
          />

          <Title text={'Website'} />
          <TextField
            fullWidth
            id='organization_url'
            name='organization_url'
            value={formik.values.organization_url}
            onChange={formik.handleChange}
            error={
              formik.touched.organization_url &&
              Boolean(formik.errors.organization_url)
            }
            helperText={
              formik.touched.organization_url && formik.errors.organization_url
            }
            variant='outlined'
            required
            sx={textFieldStyle}
          />

          <Title text={'Additional Source of Issues/Requests'} />
          <TextField
            fullWidth
            id='external_url_of_requests'
            name='external_url_of_requests'
            value={formik.values.external_url_of_requests}
            onChange={formik.handleChange}
            error={
              formik.touched.external_url_of_requests &&
              Boolean(formik.errors.external_url_of_requests)
            }
            helperText={
              formik.touched.external_url_of_requests && formik.errors.external_url_of_requests
            }
            variant='outlined'
            required
            sx={textFieldStyle}
          />
        </FormControl>
      </Box>
      {Object.keys(formik.errors).length ? (
        <Typography sx={{ marginTop: 5, color: '#fb1c48', fontWeight: '600' }}>
          Please scroll up to fix errors
        </Typography>
      ) : null}
      <ButtonAction formik={formik} loading={loading} error={error} />

      <Typography color='subColor' marginTop={3}>
        Contact us at help@unwall.xyz for adding and removing members
      </Typography>
    </Container>
  );
}

function Title({ text, fontSize = 18, color = 'main' }) {
  return (
    <Typography
      color={color}
      marginTop={5}
      fontWeight='600'
      fontSize={fontSize}
    >
      {text}
    </Typography>
  );
}

const buttonStyling = {
  borderRadius: 3,
  boxShadow: 'none',
  marginTop: 5,
  fontSize: 18,
  backgroundColor: '#1db3f9',
  color: 'main',
  '&:hover': {
    backgroundColor: 'rgb(29,179,249, 0.7)',
  },
  '&:disabled': {
    backgroundColor: 'rgb(29,179,249, 0.7)',
  },
};

function ButtonAction({ formik, loading, error }) {
  return (
    <Button
      onClick={formik.handleSubmit}
      variant='contained'
      disabled={loading}
      sx={buttonStyling}
    >
      {loading ? (
        <CircularProgress
          size={18}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : null}
      Save Changes
    </Button>
  );
}
