import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Navbar from './components/Navbar';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import BountyDetails from './components/BountyDetails';
import ProjectSubmission from './components/ProjectSubmission';
import Submissions from './components/Submissions';
import Profile from './components/Profile';
import BountyForm from './components/bounty-components/BountyForm';
import Footer from './components/Footer';
import axios from 'axios';
import Organization from './components/organizations/Organization';
import EditOrganization from './components/organizations/EditOrganization';

const theme = createTheme({
  palette: {
    main: '#black',
    primary: { main: '#black', secondary: '#949494' },
    subColor: '#949494',
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontWeight: '600',
  },
});

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/profile/:profileId' element={<Profile />} />
          <Route exact path='/organization/:orgId/bounty/new/' element={<BountyForm />} />
          <Route exact path='/organization/:orgId/bounty/:bountyId/' element={<BountyDetails />} />
          <Route
            exact
            path='/organization/:orgId/bounty/:bountyId/edit/'
            element={<BountyForm />}
          />
          <Route
            exact
            path='/organization/:orgId/bounty/:bountyId/submit/'
            element={<ProjectSubmission />}
          />
          <Route
            exact
            path='/organization/:orgId/bounty/:bountyId/submissions/'
            element={<Submissions />}
          />
          <Route
            exact
            path='/organization/:orgId/edit/'
            element={<EditOrganization />}
          />
          <Route
            exact
            path='/organization/:orgId/'
            element={<Organization />}
          />
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
