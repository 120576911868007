import { useState } from 'react';
import { CardMedia, AppBar, Box, Button, Toolbar } from '@mui/material';
import WalletModal from './modals/WalletModal';
import { useProfile } from '../contexts/ProfileContext';
import { Link } from 'react-router-dom';
import { Logo } from '../utils/images';

export default function Navbar() {
  const { user, userId, authenticated, login } = useProfile();
  return (
    <>
      <AppBar
        position='static'
        sx={{ backgroundColor: 'transparent', boxShadow: 'none', marginTop: 2 }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <Logo />
          </Link>
          <Box display='flex' alignItems='center'>
            {authenticated ? (
              <Link
                to={`profile/${userId}/`}
                style={{
                  textDecoration: 'none',
                  fontSize: 16,
                  padding: 0,
                  marginLeft: 3,
                  color: '#649ddf',
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 50 / 2,
                    marginRight: 3,
                    cursor: 'pointer',
                  }}
                  image={user.profile_picture}
                />
              </Link>
            ) : (
              <Button onClick={() => login()}>Login</Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
