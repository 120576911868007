import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { RE_FOR_URL } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import MDEditor from '@uiw/react-md-editor';
import { useProfile } from '../../contexts/ProfileContext';
import {
  Box,
  CircularProgress,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  // FormControlLabel,
  // Checkbox,
  // MenuItem,
  // Select,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  // completePaymentUponBountyCreation,
  storeFilesInIPFS,
} from '../../utils/helpers';

const textFieldStyle = {
  backgroundColor: 'main',
  padding: 0.1,
  marginTop: 2,
};

// For Creating and Editing Bounties
export default function BountyForm() {
  const params = useParams();
  const { authenticated, userId } = useProfile();
  const navigate = useNavigate();
  // const ethPrice = useEthPrice();

  const [files, setFiles] = useState([]);
  const [bounty, setBounty] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const bountyId = params.bountyId;
  const orgUniqueId = params.orgId;

  const [org, setOrg] = useState(null);

  useEffect(() => {
    if (bountyId) {
      axios
        .get(`${process.env.REACT_APP_API_SERVER}/api/bounty/${bountyId}/`)
        .then((res) => {
          setBounty(res.data);
          setDescription(res.data.description);
          // eventually setFiles will change according to it too
        })
        .catch((err) => console.log(err));
    }
  }, [bountyId]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/organization/${orgUniqueId}/`
      )
      .then((res) => setOrg(res.data))
      .catch((err) => console.log(err));
  }, [orgUniqueId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .min(8, 'At least 8 characters')
      .max(80, 'No more than 80 characters'),
    ways_to_contact: Yup.string()
      .email('Please enter a valid Email')
      .required('Email is required'),
    is_featured: Yup.bool().default(false),
    // bounty_type: Yup.string().required('What is the type of bounty'),
    // project_length: Yup.string().required('Add an est. time for the project'),
    // bounty_category: Yup.array().default([]),
    attached_job_url: Yup.string()
      .matches(RE_FOR_URL, 'URL not valid (must have http/s)')
      .nullable(),
  });

  const initialValues = {
    title: bounty ? bounty.title : '',
    funding_organization: bounty ? bounty.funding_organization : '',
    organization_url: bounty ? bounty.organization_url : '',
    ways_to_contact: bounty ? bounty.ways_to_contact : '',
    is_featured: bounty ? bounty.is_featured : false,
    // bounty_type: bounty ? bounty.bounty_type : '',
    // project_length: bounty ? bounty.project_length : '',
    // bounty_category: bounty ? bounty.bounty_category : [],
    // bounty_value_in_usd: bounty ? bounty.bounty_value_in_usd : 1000,
    attached_job_url: bounty ? bounty.attached_job_url : '',
    description: bounty ? bounty.description : '',
  };

  const formik = useFormik({
    initialValues: bounty ? bounty : initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) =>
      bountyId
        ? await saveBountyEdits(values)
        : await bountyCreationFlow(values),
    enableReinitialize: true,
  });

  // The ever changing value of totalCost
  // useEffect(() => {
  //   const bountyCost = formik.values.bounty_value_in_usd;
  //   if (coupon != null) {
  //     const couponDiscount = (100 - coupon.discount_amount) / 100;
  //     setTotalCost(bountyCost * 0.05 * couponDiscount);
  //   } else {
  //     setTotalCost(bountyCost * 0.05);
  //   }
  // }, [formik.values.bounty_value_in_usd, coupon]);

  const catchSets = () => {
    setLoading(false);
    setError(true);
  };

  const bountyCreationFlow = async (formData) => {
    setLoading(true);
    // If 100% promo coupon is used then no need to send payment

    await createBounty(formData, null);

  };

  const createBounty = async (formData, txnHash) => {
    let data = { ...formData, creator_profile: userId };
    // Image files
    const attachedFiles = await storeFilesInIPFS(files);
    data = {
      ...data,
      image_attachments: attachedFiles,
      description: description,
      organization: org.id,
    };

    const bounty = await axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/bounties/`, {
        bounty: data,
      })
      .catch((err) => {
        catchSets();
        console.log(err);
      });
    // toFixed() is inconsistent
    setLoading(false);
    navigate(`/organization/${orgUniqueId}/bounty/${bounty.data.id}/`);
  };

  const saveBountyEdits = async (formData) => {
    setLoading(true);
    let data = { ...formData, description: description };
    // Create Activity that made edits to existing Bouny
    const activity = {
      bounty: bounty.id,
      profile: userId,
      activity_type: 'Edited Bounty',
    };
    await axios
      .patch(`${process.env.REACT_APP_API_SERVER}/api/bounty/${bountyId}/`, {
        bounty: data,
        activities: activity,
      })
      .then((res) => {
        console.log('✅ bounty edited', res);
        navigate(`/organization/${orgUniqueId}/bounty/${res.data.id}/`);
      })
      .catch(() => catchSets());
    setLoading(false);
  };

  function showFilePaths(files) {
    const fileArr = Array.from(files);
    return (
      <>
        {fileArr.map((f) => (
          <Typography key={f.name} color='main'>
            {f.name}
          </Typography>
        ))}
      </>
    );
  }

  // Checks to make sure the right user is on the page
  if (bountyId && bounty == null) {
    return null;
  }

  if ((bountyId && bounty.creator_profile !== userId) || !authenticated) {
    return null;
  }

  return (
    <Container>
      <Typography color='primary' variant='h4'>
        {bountyId ? 'Edit Bounty' : 'Create Bounty'}
      </Typography>
      <FormControl style={{ width: '50%' }}>
        <Title text={'Bounty Title'} />
        <TextField
          fullWidth
          id='title'
          name='title'
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          variant='outlined'
          required
          inputProps={{ maxLength: 80 }}
          sx={textFieldStyle}
        />

        <Title text={'Point of Contact Email'} />
        <TextField
          fullWidth
          id='ways_to_contact'
          name='ways_to_contact'
          value={formik.values.ways_to_contact}
          onChange={formik.handleChange}
          error={
            formik.touched.ways_to_contact &&
            Boolean(formik.errors.ways_to_contact)
          }
          helperText={
            formik.touched.ways_to_contact && formik.errors.ways_to_contact
          }
          variant='outlined'
          required
          sx={textFieldStyle}
        />
        {bountyId ? null : (
          <>
            <Typography
              color='primary'
              marginTop={5}
              marginBottom={2}
              fontWeight={600}
              fontSize={18}
            >
              Upload Files and Attachments
            </Typography>
            <Button
              variant='contained'
              component='label'
              sx={{
                paddingTop: 1.5,
                paddingBottom: 1.5,
                backgroundColor: '#1db3f9',
                boxShadow: 'none',
                color: 'main',
                fontWeight: '600',
              }}
            >
              Upload Files
              <input
                alt='image in here'
                type='file'
                name='image'
                hidden
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />{' '}
            </Button>{' '}
          </>
        )}
        {files ? showFilePaths(files) : null}

        <Title text={'Optional: Link of additional description'} />
        <TextField
          fullWidth
          id='attached_job_url'
          name='attached_job_url'
          value={formik.values.attached_job_url}
          onChange={formik.handleChange}
          error={
            formik.touched.attached_job_url &&
            Boolean(formik.errors.attached_job_url)
          }
          helperText={
            formik.touched.attached_job_url && formik.errors.attached_job_url
          }
          variant='outlined'
          sx={textFieldStyle}
        />
      </FormControl>
      <Typography marginTop={5} variant='h6' color='primary' fontWeight={600}>
        Description
      </Typography>
      <Typography
        fontWeight='600'
        color='#757575'
        variant='body2'
        marginTop={2}
      >
        The most successful bounties will be the most in depth about what's
        needed!
      </Typography>
      <MDEditor
        style={{ marginTop: 20 }}
        height={500}
        value={description}
        onChange={setDescription}
      />
      {Object.keys(formik.errors).length ? (
        <Typography sx={{ marginTop: 5, color: '#fb1c48', fontWeight: '600' }}>
          Please scroll up to fix errors
        </Typography>
      ) : null}
      <ButtonAction
        bountyId={bountyId}
        formik={formik}
        loading={loading}
        error={error}
      />
    </Container>
  );
}

function Title({ text, fontSize = 18, color = 'main' }) {
  return (
    <Typography
      color={color}
      marginTop={5}
      fontWeight='600'
      fontSize={fontSize}
    >
      {text}
    </Typography>
  );
}

const buttonStyling = {
  borderRadius: 3,
  boxShadow: 'none',
  marginTop: 5,
  fontSize: 18,
  backgroundColor: '#1db3f9',
  color: 'main',
  '&:hover': {
    backgroundColor: 'rgb(29,179,249, 0.7)',
  },
  '&:disabled': {
    backgroundColor: 'rgb(29,179,249, 0.7)',
  },
};

function ButtonAction({ bountyId, formik, loading, error }) {
  if (!bountyId) {
    return (
      <Box position='relative'>
        <Button
          onClick={formik.handleSubmit}
          variant='contained'
          disabled={loading}
          sx={buttonStyling}
        >
          {loading ? (
            <CircularProgress
              size={18}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          ) : null}
          Create Bounty
        </Button>
        {error ? (
          <Typography color='#fb1c48'>
            Something went wrong, please try again later
          </Typography>
        ) : null}
        {/* <Typography color='main' marginTop={2}>
          Total Cost: ${totalCost} USD (5% of bounty reward)
        </Typography>
        <Typography color='subColor' marginBottom={15} fontWeight='600'>
          *Paid out in Eth
        </Typography> */}
      </Box>
    );
  }

  return (
    <Button
      onClick={formik.handleSubmit}
      variant='contained'
      disabled={loading}
      sx={buttonStyling}
    >
      {loading ? (
        <CircularProgress
          size={18}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : null}
      Save Changes
    </Button>
  );
}
